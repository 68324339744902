<mat-drawer-container autosize class="wrapper">
  <mat-drawer #drawer class="main-sidenav" mode="side" opened>
    <mat-nav-list class="left-menu mt-4">
      <app-menu></app-menu>
    </mat-nav-list>
  </mat-drawer>

  <div class="sidenav-content">
    <mat-toolbar>
      <button (click)="drawer.toggle()" aria-label="Example icon-button with menu icon" class="example-icon"
              mat-icon-button>
        <mat-icon>menu</mat-icon>
      </button>
      <span>HR SERVIZIO SALUTE</span>
      <span class="menu-spacer"></span>
      <app-right-navbar-links></app-right-navbar-links>
    </mat-toolbar>
    <router-outlet></router-outlet>

  </div>

</mat-drawer-container>

