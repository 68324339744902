
import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarEventService {
  sidebarChange: EventEmitter<void> = new EventEmitter();
  constructor() {}
  changeSidebarState(): void{
    this.sidebarChange.emit();
  }
  getSidebarState(): EventEmitter<void>{
    return this.sidebarChange;
  }
}
