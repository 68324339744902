import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {AuthService} from '../../../../shared/services/auth.service';

@Component({
  selector: 'app-right-navbar-links',
  templateUrl: './right-navbar-links.component.html',
  styleUrls: ['./right-navbar-links.component.css']
})
export class RightNavbarLinksComponent implements OnInit {

  constructor(
    private auth: AuthService,
    private router: Router) { }

  ngOnInit(): void {
  }

  logout(e: Event): void{
    e.preventDefault();
    this.auth.logout();
    this.router.navigate(['/login']);
  }

}
