import {Component, HostBinding, OnInit} from '@angular/core';
import {environment} from '../../environments/environment'; 

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  project!: string;

  @HostBinding('class') class = 'wrapper ng-star-inserted';
  constructor() {
  }

  ngOnInit(): void {
    this.project = environment.project; // Imposta il valore del progetto dall'ambiente
  }

}
